.wrap-className {
  justify-content: flex-end;
}

.side-screen-transform-hidden {
  transform: translateX(100%);
  transition-duration: 600ms;
}

.side-screen-transform-show {
  transform: translateX(0);
  transition-duration: 600ms;
}

.side-screen-modal {
  height: 100%;
  width: 80%;

  .am-modal-content:first-child {
    padding-top: 0;
    border-radius: 0;

    .am-modal-body:first-child {
      padding: 0;
    }
  }

  .side-content {
    width: calc(100% - 15px);
    height: calc(100% - 49px);
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-left: 15px;
    padding-bottom: 69px;
    .item-title {
      display: inherit;
      font-size: 13px;
      color: #444444;
      margin-top: 30px;
      .subtitle {
        color: #3f3f3f;
        margin-left: 8px;
      }
    }
    .item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item-button {
        width: 25%;
        height: 32px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 32px;
        text-align: center;
        margin: 22px 20px 0 0;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    .cancel-button {
      width: 50%;
      height: 49px;
      font-size: 14px;
      display: inline-block;
      line-height: 49px;
      text-align: center;
      background-color: #fafafa;
      color: #444444;
    }
    .affirm-button {
      background-image: linear-gradient(-133deg, #62afff 0%, #3377ff 100%);
      color: #ffffff;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;