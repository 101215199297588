.join-store-develop{
  width:100%;
  height:3.5rem;
  background-color: #ffffff;
  border-radius: 0.15rem;
  padding: 0 0.2rem 0 0.2rem;
  box-sizing: border-box;
  margin-top: 0.2rem;
  margin-bottom:0.2rem;
  .top-wrap{
    width: 100%;
		height: 0.86rem;
		position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-left{
      .left-image {
				width: 0.3rem;
				height: 0.3rem;
				margin-top:-0.04rem;
      }
      .left-title {
				margin-left: 10px;
				font-size: 36px;
				color: #333333;
				font-weight: bold;
			}
    }
    .top-right{
      display: flex;
      align-items: center;
      .right-title {
				font-size: 24px;
				color: #4a90e2;
			}

			.right-sub-title {
				font-size: 24px;
				color: #333333;
			}
    }
  }
  .main-cont{
    width:100%;
    height:2.64rem;
    padding-top:0.15rem;
    box-sizing: border-box;
    .total-box{
      //padding:0.15rem 0 0.29rem;
      width:100%;
      height:0.59rem;
      //line-height: 0.59rem;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 0.29rem;
      .name{
        height:0.59rem;
        font-size: 0.28rem;
        line-height: 0.59rem;
        color: #333333;
        margin-right:0.2rem;
      }
      .num{
        height:0.59rem;
        font-size: 0.42rem;
        line-height: 0.5rem;
        color: #333333;
        font-weight: bold;
      }
    }
    .section-box{
      width:100%;
      height:1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .section{
        width:2rem;
        display: flex;
        height:100%;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        background: linear-gradient(135deg, #71A6FF 0%, #0C63FF 100%);
        border-radius: 0.12rem;
        margin-right:0.1rem;
        .sec-num{
          font-size: 0.42rem;
          height:0.59rem;
          line-height: 0.59rem;
          color: #FFFFFF;
          margin-bottom:0.05rem;
          font-weight: bold;
        }
        .sec-name{
          font-size: 0.24rem;
          color: #FFFFFF;
        }
      }
      .section2{
        background:  linear-gradient(135deg, #71A6FF 0%, #0C63FF 100%);
        opacity: 0.8;
      }
      .section3{
        background: #F5F5F5;
        margin-right:0;
        .sec-num{
          color: #BBBBBB;
        }
        .sec-name{
          color: #BBBBBB;
        }
      }
    }
  }
}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;