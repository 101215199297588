/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-11-05 20:31:24
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-11-15 14:30:10
 * @Description: Description
 */
.text-avatar {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 50%;
  font-weight: normal;
  font-size: 0.32rem;
  text-align: center;
  color: #fff;
  background-color: #34BB9C;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;