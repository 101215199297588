.table-wrapper {
  position: relative;
  background-color: #fff;
  p {
    margin: 0;
    padding: 0;
  }
  .table-fixed {
    position: absolute;
    background-color: #fff;
    border-right: 1px solid #dddddd;
    z-index: 11;
  }
  .loading {
    text-align: center;
    padding: 0.28rem 0;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;