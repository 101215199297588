.zoom-tool-wrap{
  position: absolute;
  right:0.33rem;
  bottom:0.46rem;
  width:0.6rem;
  height:1.4rem;
  background: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.10);
  border-radius: 4px;
  div{
    width:100%;
    height:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      display: block;
      max-width:30px;
      width:30px;
      height:30px;
    }
  }
}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;