.line {
  // width: 100%;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center; // margin-bottom: 0.2rem;
  background: #f0f1f4;
  height: 1px;
  margin: 20px 0;

  // width: calc(100% - 10px)
  // .divider {
  //   width: 90%;
  //   height: 1px;
  // }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;