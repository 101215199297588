.home-stock-data {
	background-color: #ffffff;
	padding: 0 0.2rem 0.2rem;
	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
	border-radius: 0.15rem;

	.stock-title {
		width: 100%;
		height: 0.86rem;

		.image {
			width: 32px;
			height: 32px;
			margin-top:-4px;
		}

		.sub-title {
			margin-left: 10px;
			font-size: 36px;
			color: #333333;
			font-weight: bold;
		}
		.home-warning-store-sub-item-detail{
			font-size: 0.24rem;
			color: #4a90e2;
		}
	}

	.divider {
		margin: 0 0 0.02rem;
	}

	.stock-content {
		width: 100%;

		.in-item {
			height: 1.4rem;
			.number {
				font-size: 0.42rem;
				font-weight: bold;
				color: #333333;
			}
			.name {
				font-size: 0.24rem;
				color: #666666;
				margin-top: 0.2rem;
			}
		}
	}
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;