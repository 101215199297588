.task-item{
    padding:30px 0px 30px 0px;
    background: #FFFFFF;
    border-radius: 12px;
    width:702px;
    margin:18px 24px 18px 24px;
   .task-item-wrap {
       display: flex;
       justify-content: space-between;
       padding-left:24px;
       padding-right: 24px;
       margin-bottom: 12px;
       .task-item-title{
           display: flex;
           font-family: PingFangSC-Semibold;
           font-size: 32px;
           color: #333333;
           letter-spacing: 0;
           line-height: 32px;
           font-weight: bold;
       }
       .task-item-arrow {
           img {
               width:30px;
               height: 30px;
           }
       }
   }
   .task-item-view{
       display: flex;
       letter-spacing: 0;
       line-height: 28px;
       font-family: PingFangSC-Regular;
       font-size: 28px;
       margin-top:18px;
       padding-left:24px;
       padding-right: 24px;
       .task-item-text{
           color: #999999;
          
       }
       .task-item-time{
           color: #333333;
       }
       .task-item-schedule{
           display: flex;
           justify-content: center;
           align-items: center;
           background: #3377FF;
           border-radius: 17px;
           font-family: PingFangSC-Semibold;
           font-size: 24px;
           color: #FFFFFF;
           line-height: 24px;
           padding:0px 12px 0px 12px;
           height: 32px;

       }
       .task-item-termination-num{
           display: flex;
           justify-content: center;
           align-items: center;
           width:39px;
           height: 32px;
           border-radius: 17px;
           color: #FFFFFF;
           font-size: 24px;
           font-family: PingFangSC-Semibold;
           background: #FF8800;
       }
   }
}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;