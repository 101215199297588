.skeleton-page {
  background-color: #f2f2f2;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.skeleton-block {
  // margin-bottom: 20px;
  background-color: #fff;
  // border: 1px solid #f3f3f3;
  box-shadow: inset 0 -1px 0 0 #e3e3e3;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
}

.flex-h-contanier {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-v-contanier {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.border {
  border-right: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

.full-width {
  width: 100%;
}

.w-600 {
  width: 600px;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.skeleton-rect--animated {
  background: linear-gradient(90deg, #f2f2f2 25%, #ecebeb 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

.skeleton-rect {
  background-color: #f2f2f2;
}

.skeleton-rect-inverse--animated {
  background: linear-gradient(90deg, #fff 25%, #f2f2f2 37%, #fff 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}

.skeleton-rect-inverse {
  background-color: #fff;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;