.cl-sr {
  padding: 0.4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .sr-echart-no-data {
    height: 2.44rem;
    background: #eee;
    width: 2.44rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;