/*
 * @Author: keqingrong (19040892)
 * @Date: 2019-07-26 10:01:45
 * @LastEditors: keqingrong (19040892)
 * @LastEditTime: 2019-08-22 15:20:03
 * @Description: 通用页面样式
 */
.page {
  background-color: #f2f2f2;
  height: 100%;
}
