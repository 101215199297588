.shop-purchase-performance {
  .operating-data {
    background-color: #ffffff;
    border-radius: 0.15rem;
    padding: 0 0.2rem 0 0.2rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: column;
  }

  .top {
    width: 100%;
    height: 0.86rem;
    position: relative;
    border-bottom: 1px solid #f0f1f4;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      display: flex;
      align-items: center;

      .left-image {
        width: 0.32rem;
        height: 0.32rem;
        margin-top: -0.02rem;
        display: block;
      }

      .left-sub-image {
        height: 50px;
        margin-left: 0.1rem;
      }

      .left-title {
        margin-left: 10px;
        font-size: 0.36rem;
        color: #333333;
        font-weight: bold;
      }

      .left-title-small {
        font-size: 0.24rem;
        color: #999;
        margin-left: 0.1rem;
      }

      .left-sub-title {
        margin-left: 10px;
        font-size: 28px;
        color: #333333;
      }
    }

    .top-right {
      display: flex;
      align-items: center;

      .right-title {
        font-size: 24px;
        color: #4a90e2;
      }

      .right-sub-title {
        font-size: 24px;
        color: #333333;
      }
    }

    .top-line {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e3e3e3;
    }
  }

  .main-wrap {
    width: 100%;
    height: 3.86rem;
    padding: 0.3rem 0rem;
    box-sizing: border-box;

    .switch-outBox {
      position: relative;
      margin: 0 auto;
    }

    .operating-data-content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .operating-section {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.3rem;

        .operating-section-title {
          height: 0.59rem;
          margin-bottom: 0.12rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .num {
            display: block;
            font-size: 0.42rem;
            font-weight: bold;
            color: #333333;
          }

          .numName {
            display: block;
            font-size: 0.26rem;
            color: #151515;
            margin-top: 10px;
          }
        }

        .operating-section-name {
          height: 0.34rem;
          font-size: 0.24rem;
          color: #666666;
        }
      }
    }
  }

  .waring-store {
    .warning-data-box {
      width: 100%;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;

      .warning-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-top: 0.3rem;

        .num {
          font-size: 0.42rem;
          color: #333333;
          font-weight: bold;
          line-height: 0.6rem;
          margin-bottom: 0.12rem;
        }

        .name {
          font-size: 0.24rem;
          color: #666666;
        }
      }
    }
  }

}

.shop-performance-home-purchase-modify {
  padding: 0 0.2rem 0.3rem 0.2rem !important;

  .line {
    margin: 0 0 0.2rem;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;