/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-28 14:28:36
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-11-21 10:11:10
 * @Description: Description
 */
.evaluation-label {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 32px;
  width: 88px;
  height: 32px;
  vertical-align: top;
}

.evaluation-label-info {
  color: #3377FF;
  background-color: #E4EFFE;

  .evaluation-label-dot {
    opacity: 0.5;
    background-color: #3377FF;
  }
}

.evaluation-label-warning {
  color: #FFFFFF;
  background-color: #F7B400;

  .evaluation-label-dot {
    opacity: 0.5;
    background-color: rgba(255, 255, 255, 0.50);
  }
}

.evaluation-label-error {
  color: #FFFFFF;
  background-color: #F64F4F;

  .evaluation-label-dot {
    opacity: 0.5;
    background-color: rgba(255, 255, 255, 0.50);
  }
}

.evaluation-label-dot {
  position: absolute;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.evaluation-label-dot-left {
  top: 13px;
  left: 13px;
}

.evaluation-label-dot-right {
  top: 13px;
  right: 13px;
}

.evaluation-label-title {
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0;
}

// android 垂直居中兼容
.evaluation-label-android {
  .evaluation-label-title {
    padding-top: 4px;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;