.tab-bars-wrapper {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  z-index: 30;
}

.tab-bar-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: .2rem;
  align-items: center;
  color: rgb(148, 148, 148);

  &-name {
    margin: 0.06rem 0 0 0;
    line-height: 1;
  }

  &-icon {
    width: .4rem;
    height: .4rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;

    &.home {
      background-image: url(../../../../assets/images/shuju_n@2x.png);
    }

    &.message {
      position: relative;
      background-image: url(../../../../assets/images/messageDefaultIcon.png);
    }

    &.dashboard {
      background-image: url(../../../../assets/images/gongzuotai_n@2x.png);
    }
    &.todos {
      background-image: url(../../../../assets/images/todo@2x.png);
    }
  }

  &.active {
    color: rgb(51, 119, 255);

    .home {
      background-image: url(../../../../assets/images/shuju_h@2x.png);
    }

    .message {
      background-image: url(../../../../assets/images/messageTab@1.5x.png);
    }

    .dashboard {
      background-image: url(../../../../assets/images/gongzuotai_h@2x.png);
    }
    .todos {
      background-image: url(../../../../assets/images/todo-active@2x.png);
    }
  }

  &.with-unread {
    .message {
      &::after {
        content: '';
        position: absolute;
        transform: translateX(-50%);
        transform-origin: 0 center;
        top: -.08rem;
        right: -.15rem;
        height: .16rem;
        width: .16rem;
        border-radius: 100%;
        background: #ff5b05;
        z-index: 10;
      }
    }

  }
  &.with-unread-to-do {
    position: relative;
    .todos {
      &::after {
        content: '';
        position: absolute;
        transform: translateX(-50%);
        transform-origin: 0 center;
        top: -.08rem;
        right: 0.58rem;
        height: .17rem;
        width: .17rem;
        border-radius: 100%;
        background: #ff5b05;
        z-index: 10;
      }
    }

  }
}


@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;