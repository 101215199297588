.segment-control-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 16px;
  height: 52px;

  .left-control {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 160px;
    border-radius: 16px;
    box-sizing: content-box;
    align-items: center;
    justify-content: flex-end;
  }

  .right-control {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 160px;
    border-radius: 16px;
    box-sizing: content-box;
    margin-left: -10px;
    margin-right: -20px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  .default-button {
    background-color: transparent;
    font-family: PingFangSC-Regular;
    font-size: 26px;
    color: #666666;
    text-align: center;
    width: 160px;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    border: none;
    padding: 0;
    background: #f5f5f5;
  }

  .left-state {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .right-state {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .active-button {
    background: #3377ff;
    border-radius: 16px;
    font-family: PingFangSC-Regular;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
    width: 160px;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    border: none;
    z-index: 100;
  }

  .unread-number {
    position: absolute;
    top: -0.06rem;
    right: -0.22rem;
    background: #ff3300;
    border-radius: 18px;
    font-size: 22px;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    width: 54px;
    height: 36px;
    z-index: 999;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;