.home-open-store {
  background-color: #ffffff;
  padding: 20px 20px 30px 20px;
  margin-bottom: 20px;
  border-radius: 15px;

  &-sub-item {
    width: 100%;
    height: 0.86rem;
    position: relative;
    box-sizing: border-box;

    &-title {
      margin-left: 10px;
      font-size: 36px !important;
      color: #333333;
      font-weight: bold;
    }

    &-detail {
      font-size: 24px;
      color: #4a90e2;
    }

    &-image {
      width: 32px;
      height: 32px;
      margin-top: -5px;
    }
  }

  &-store-task {
    width: 600px;
    height: 60px;
    background: #f2f2f2;
    border-radius: 16px;
    margin: 50px 0;
    font-size: 30px;

    &-money {
      color: #426ffe;
      font-weight: bold;
      font-size: 36px;
    }

    &-unit {
      color: #426ffe;
      font-weight: bold;
      font-size: 30px;
      margin-left: 4px;
    }
  }

  &-store-task-number {
    width: 100%;

    &-item {
      display: flex;
      flex-direction: column;
      font-size: 50px;
      color: #333333;
      font-weight: bold;
    }

    &-title {
      font-size: 24px;
      color: #666666;
      margin-top: 20px;
      font-weight: normal;
    }
  }

  &-store-err {
    height: 180px;
    color: red;
  }
}

.home-open-store-modify {
  background-color: #ffffff;
  padding: 0 0.2rem 0.3rem 0.2rem !important;
  margin-bottom: 0.2rem;
  border-radius: 15px;

  .home-purchase-sub-item-detail {
    font-size: 0.24rem;
    color: #4a90e2;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;