.dev-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
}

.dev-menu-item {
  box-sizing: border-box;
  user-select: none;
  font-family: sans-serif;
  color: #fff;
  background-color: #04BE02;
  border-bottom: 1px solid #fff;
  line-height: 1;
  width: 200px;
  padding: 16px 24px;

  &:last-child {
    border-bottom: none;
  }

  > a {
    color: #fff;
  }
}


@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;