.achievement {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0 0.2rem 0 0.2rem;
  box-sizing: border-box;
  margin-bottom: 0.2rem;

  .top {
    width: 100%;
    height: 0.86rem;
    position: relative;
    border-bottom: 1px solid #f0f1f4;
    box-sizing: border-box;

    .top-left {
      .left-image {
        width: 0.3rem;
        height: 0.3rem;
        margin-top: -0.04rem;
      }

      .left-sub-image {
        height: 50px;
        margin-left: 0.1rem;
      }

      .left-title {
        margin-left: 10px;
        font-size: 36px;
        color: #333333;
        font-weight: bold;
      }

      .left-sub-title {
        margin-left: 10px;
        font-size: 28px;
        color: #333333;
      }
    }

    .top-right {

      .right-title {
        font-size: 24px;
        color: #4a90e2;
      }

      .right-sub-title {
        font-size: 24px;
        color: #333333;
      }
    }

    .top-line {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e3e3e3;
    }
  }

  .top-grade {
    width: 100%;
    position: relative;
    padding-top: 0.25rem;
    box-sizing: border-box;

    .top-left {

      .left-image {
        width: 30px;
        height: 30px;
      }

      .left-sub-image {
        height: 50px;
        margin-left: 0.1rem;
      }

      .left-title {
        margin-left: 10px;
        font-size: 36px;
        color: #333333;
        font-weight: bold;
      }

      .left-sub-title {
        margin-left: 10px;
        font-size: 28px;
        color: #333333;
        white-space: nowrap;
      }
    }

    .top-right {

      .right-title {
        font-size: 24px;
        color: #4a90e2;
      }

      .right-sub-title {
        font-size: 24px;
        color: #333333;
      }
    }

    .top-line {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #e3e3e3;
    }
  }

  .contents {
    width: 100%;
    height: 168px;

    .title {
      margin-top: 12px;
      font-size: 24px;
      color: #666666;
    }

    .content {
      font-size: 42px;
      color: #333333;
      font-weight: bold;
      height: 59px;
      line-height: 59px;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;