.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .name {
    font-size: 0.24rem;
    color: #666666; // margin: 0.1rem 0 0.1rem 0;
  }
  .value {
    font-size: 0.34rem;
    color: #333333;
    font-weight: bold;
    margin: 0.1rem 0 0.1rem 0;
  }
  .des {
    font-size: 0.24rem;
    color: #666666; // margin: 0.1rem 0 0.1rem 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
  .des-h {
    background: #d3e6ff;
    border-radius: 0.14rem;
  }
  .des-l {
    background: #f1f1f1;
    border-radius: 0.14rem;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;