.task-manage-block {
  width: 100%;
  height: 0.92rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 0.15rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .task-left-block {
    display: flex;
    width: 2rem;
    align-items: center;

    .task-img {
      display: block;
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.1rem;
    }

    .task-title {
      font-size: 0.36rem;
      font-weight: bold;
      color: #333333;
    }
  }

  .task-right-block {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .task-title {
      font-size: 0.28rem;
      color: #333333;
      margin-right: 0.08rem;
    }

    .num-box {
      width: 0.34rem;
      height: 0.34rem;
      background: #F64F4F;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-weight: bold;
    }

    .num-any-box {
      padding: 0 0.09rem;
      box-sizing: border-box;
      background: #F64F4F;
      height: 0.34rem;
      text-align: center;
      color: #fff;
      border-radius: 16px;
      font-weight: bold;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;