.order-num {
  top: 0;
  left: 0;
  position: absolute;
  width: 0.52rem;
  height: 0.52rem;
  .order {
    position: absolute;
    font-family: PingFangSC-Semibold;
    font-size: 0.24rem;
    color: #ffffff;
    text-align: center;
    left: 0.08rem;
    z-index: 10;
    top: 0;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -0.35rem;
    top: -0.35rem;
    width: 0;
    height: 0;
    border: 0.35rem solid transparent;
    border-right: 0.35rem solid #4b92f5;
    transform: rotateZ(45deg);
    z-index: 0;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;