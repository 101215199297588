@import '../varible.less';
.switch-day {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	height: 0.8rem;
	overflow-x: scroll;
	.item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// width: 1rem;
		margin: 0 0.4rem 0 0.4rem;
		.title-b {
			line-height: 0.7rem;
			font-size: 0.28rem;
			color: @primary-color;
			border-bottom: 0.06rem solid @primary-color;
		}
		.title-nb {
			line-height: 0.7rem;
			font-size: 0.28rem;
			color: #333333;
			border-bottom: 0.06rem solid #fff;
		}
	}
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;