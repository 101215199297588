
.detail-no-data-view{
    display: flex;
    flex-direction: column;
    align-items: center;
    .running-lion{
        width: 300px;
        height: 300px;
        margin-top: 300px;
    }
    .no-data-title{
        margin-top: 30px;
    }
}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;