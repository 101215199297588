.components-again-inspection {
  padding: 13px 40px 12px 40px;
  width: 100%;
  background: #FFEEEE;
  box-sizing: border-box;


  .button {
    height: 38px;
    width: 128px;
    border-radius: 0.3rem;
    font-size: 0.24rem;
    color: #B34F00;
    border: 1px solid #B34F00;
    box-sizing: border-box;
    font-weight: bold;
  }

  .hint {
    font-size: 0.24rem;
    color: #B34F00;
    margin-left: 0.1rem;
  }

  .hint-number {
    font-weight: bold;
    padding: 0 4px;
  }

  .unsupport-icon {
    width: 0.28rem;
    height: 0.28rem;
  }
}

.home-again-inspection-blank {
  margin-top: 0px;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;