.home-aera-popup-list {
  .am-list-line .am-list-content {
    color: #333333;
    font-size: 28px;
  }

  .isSelected {
    background-color: #F2F2F2;

    .am-list-line .am-list-content {
      color: #3377FF;
    }
  }

  .list-content {
    width: 100%;
    max-height: 600px;
    overflow: scroll;

  }

  .list-item {
    width: calc(100% - 30px);
  }
}

.home-aera-popup-list-title {
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}

.custom-btn-overwrite.am-button-active {
  background-color: #3377FF;
  opacity: 0.8;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;