.home-container {
  background: #f0f0f0;
}

.test-content {
  width: 7.02rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 1.6rem;
  margin-bottom: 0.2rem;
}

.out-home-container {
  .am-tabs-pane-wrap {
    -webkit-overflow-scrolling: touch;
  }
}

.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.placeholder {
  background-color: #ebebef;
  color: #bbb;
  text-align: center;
  margin: 14px;
  padding: 18px 40px;
}

.test-title {
  margin: 24px;
  line-height: 40px;
  text-align: center;
}

.debug {
  width: 100%;
  min-height: 88px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &>div {
    padding: 20px;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;