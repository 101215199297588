.work-bench {
  padding: 0 0.2rem;
  border-radius: 0.02rem;

  .blue-view {
    margin-top: 20px;
    background-image: linear-gradient(269deg, #4B98FF 0%, #4170FE 100%);
    border-radius: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .in-item {
      width: 33.3%;
      height: 1.43rem;
      position: relative;

      .sample-value {
        font-size: 0.46rem;
        color: #FFFFFF;
      }

      .sub-title {
        font-size: 0.26rem;
        color: #FFFFFF;
      }
    }

    .in-item:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0.38rem;
      width: 0.02rem;
      height: 0.72rem;
      background: rgba(255, 255, 255, 0.2);
    }

    .in-item:nth-child(3n):after {
      content: '';
      position: absolute;
      right: 0;
      top: 0.38rem;
      width: 0;
      height: 0.72rem;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .sample-value {
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
  }

  .sub-title {
    font-size: 22px;
    color: #cadeff;
    letter-spacing: 0;
    margin-top: 10px;
  }

  .sub-title-grey {
    font-size: 22px;
    color: #666666;
    letter-spacing: 0;
  }

  .work-bench-item {

    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 0.2rem;

    .title {
      color: #333333;
      font-size: 0.3rem;
      font-weight: bold;
      padding: 0.2rem 0 0 0.2rem;
    }

    .cell-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .cell-item {
        width: 25%;
        height: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          background: #ffffff;
          width: 0.78rem;
          height: 0.78rem;
        }

        .name {
          margin-top: 0.15rem;
          font-size: 0.24rem;
          color: #999999;
        }
      }
    }

  }
}

.work-bench-nodata-center {
  text-align: center;
  padding-top: 3rem;
  box-sizing: border-box;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;