.message-tab-view {
  width: 100%;
  overflow: hidden;

  .system-box {
    .system-time {
      width: 7.5rem;
      height: 0.75rem;
      line-height: 0.75rem;
      text-align: center;
      background-color: #f2f2f2;
      font-family: PingFangSC-Regular;
      font-size: 0.26rem;
      color: #999999;
    }

    .system-essay {
      width: 100%;
      border-bottom: 0.01rem solid #F2F2F2;
      display: flex;
      background-color: white;
      flex-direction: column;
      padding: 0.2rem 0.3rem;
      box-sizing: border-box;

      .tool-box {
        .tool-dot {
          width: 0.12rem;
          height: 0.12rem;
          display: inline-block;
          background-color: transparent;
          border-radius: 0.18rem;
          margin-left: -0.17rem;
          margin-bottom: 0.03rem;
        }

        .tool-dotActive {
          width: 0.12rem;
          height: 0.12rem;
          display: inline-block;
          background-color: #FF3300;
          border-radius: 0.18rem;
          margin-left: -0.17rem;
          margin-bottom: 0.03rem;
        }

        .tool-stext {
          font-weight: bold;
          font-size: 0.3rem;
          color: #333333;
          padding-left: 0.10rem;
        }
      }

      .tool-text {
        margin-top: 0.25rem;
        font-size: 0.26rem;
        width: 100%;
        color: #151515;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .footer-right-text {
        text-align: right;
        color: #3377FF;
        padding: 0.1rem 0 0;
      }
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;