.selectSeason {
  display: flex;
  flex-direction: row;
  width: 2.8rem;
  height: 0.6rem;
  border-radius: 0.08rem;
  border: 1px solid #eee;
  background: #fff;
  align-items: center;
  padding: 0 0.2rem;
  position: relative;
  margin: 0 0 0.2rem 0.24rem;
  color: #333;
  font-size: 0.26rem;
  .date {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 1.9rem;
    font-size: 0.26rem;
    color: #333;
    margin-top: .05rem;
    // position: absolute;
    // top: .21rem;
    // left: .6rem;
  }
  img {
    width: 0.3rem;
    height: 0.3rem;
    margin-right: .1rem;
    // position: absolute;
    // top: .18rem;
    // left: .2rem;
  }
  .arrow-bottom {
    font-size: 0;
    line-height: 0;
    border-width: 0.12rem;
    border-color: #bbb;
    border-bottom-width: 0;
    border-style: dashed;
    border-top-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    position: absolute;
    top: 0.26rem;
    right: 0.3rem;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;