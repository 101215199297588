.snrc-image-view {
  display: inline-block;
  position: relative;
  padding: 6px;

  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .addImgBox {
    width: 100%;
    height: 100%;
    position: relative;

    span {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0.16rem;
      color: #BEBEBE;
      text-align: center;
    }
  }

  .delete-image {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;