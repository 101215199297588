.home-role-storeType-modal {
  .am-modal-content {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  .am-picker-col-indicator::before {
    height: 0 !important;
  }

  .am-picker-col-indicator::after {
    height: 0 !important;
  }

  .am-list-body::after {
    height: 0 !important;
  }

  .am-list-body::before {
    height: 0 !important;
  }

  .home-role-popup-tip {
    text-align: left;
    font-size: 28px;
    color: #999999;
    padding-left: 24px;
    margin-bottom: 18px;
  }

  .header {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: row;
  }

  .left,
  .right {
    width: 80px;
  }

  .middle {
    flex: 1;
    font-size: 32px;
    color: #222222;
  }

  .align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-icon {
    width: 30px;
    height: 30px;
  }

  .home-role-popup-content {
    width: 100%;
    box-sizing: border-box;
    min-height: 600px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
  }

  .home-role-list,
  .home-storeType-list {
    flex: 1;
  }

  .list-view-container {
    height: 100%;
  }

  .list-item {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 28px;
    font-weight: 400;
    color: #222222;
  }

  .item-selected {
    font-weight: 500;
    color: #3377FF;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;