.warn-title-box{
  display: flex;
  align-items: center;
  padding:0.2rem 0.24rem;
  box-sizing: border-box;
  .warning-img {
    display:block;
    width: 0.32rem;
    height: 0.32rem;
    margin-right: 0.1rem;
  }
  .warn-title{
    font-family: PingFangSC-Regular;
    font-size: 0.26rem;
    flex:1;
    line-height: 0.37rem;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;