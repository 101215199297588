@import '../varible.less';

.radio-bar {
  height: 0.88rem;
  background: #fff;
  // border-top: 1px solid #000;
  // border-bottom: 1px solid #000;
  width: 100%;

  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }

  ul {
    height: 0.56rem;
    margin: 0;
    padding: 0.16rem 0.13rem 0.16rem 0.28rem;
    overflow-x: auto;
    white-space: nowrap;

    li {
      display: inline-block;
      text-align: center;
      list-style: none;
      margin-right: 0.15rem;
      padding: 0 0.2rem;
      height: 0.56rem;
      line-height: 0.56rem;
      font-family: PingFangSC-Medium;
      font-size: 0.28rem;
      color: @primary-color;
      border-radius: 0.28rem;
      border: 0.01rem solid @primary-color;
    }

    .active {
      background: @primary-color;
      border-radius: 0.28rem;
      color: #ffffff;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;