.todo-item-data-wrap{

    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  .img-wrap{
    display: flex;
    align-items: center;
    justify-items: center;
    width:750px;
    margin-top:333px;
    img {
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }

  }
  .error-text {
    font-family: PingFangSC-Light;
    font-size: 26px;
    color: #666666;
    letter-spacing: 0;
    text-align: center;
    line-height: 54px;
    margin-top:8px;
  }
  .refresh-button{
    width: 220px;
    height: 54px;
    font-size: 28px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    background: #3377FF;;
    border-radius: 12px;
    margin-top:24px;
    justify-content: center;
  }

}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;