.open-store-achievement {
  width: 100%;
  height: 2.51rem;
  background-color: #ffffff;
  border-radius: 0.15rem;
  padding: 0 0.2rem 0 0.2rem;
  box-sizing: border-box;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  .top-wrap {
    width: 100%;
    height: 0.86rem;
    position: relative;
    border-bottom: 1px solid #f0f1f4;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      .left-image {
        width: 0.3rem;
        height: 0.3rem;
        margin-top: -0.04rem;
      }

      .left-title {
        margin-left: 10px;
        font-size: 36px;
        color: #333333;
        font-weight: bold;
      }
    }

    .top-right {
      display: flex;
      align-items: center;

      .right-title {
        font-size: 24px;
        color: #4a90e2;
      }

      .right-sub-title {
        font-size: 24px;
        color: #333333;
      }
    }
  }

  .main-cont {
    width: 100%;
    height: 1.65rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .section {
      display: flex;
      flex-direction: column;
      text-align: center;

      .number {
        font-size: 0.42rem;
        color: #333333;
        margin-bottom: 0.12rem;
        font-weight: bold;
      }

      .text {
        font-size: 0.24rem;
        color: #666666;
      }
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;