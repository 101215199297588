.vsa-prototype-container {
  width: 100%;
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  padding: 0 0.2rem 0px 0.2rem;
  box-sizing: border-box;

  .header-container {
    height: 96px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header-left {
      display: flex;
      align-items: center;

      .left-img {
        width: 0.32rem;
        height: 0.32rem;
        margin-top: -0.02rem;
        display: block;
      }

      .left-title {
        margin-left: 0.1rem;
        font-size: 0.36rem;
        color: #333333;
        font-weight: bold;
      }
    }

    .header-right {
      display: flex;
      align-items: center;

      .right-title {
        font-size: 0.24rem;
        color: #4a90e2;
      }
    }
  }

  .ranking-container {
    height: 0.6rem;
    width: 100%;
    background: #F0F4FF;
    border-radius: 0.18rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0.18rem;
    padding-right: 0.18rem;
    box-sizing: border-box;

    .ranking-left {
      display: flex;
      align-items: center;

      .left-img {
        width: 0.32rem;
        height: 0.32rem;
        margin-top: -0.02rem;
        display: block;
      }

      .ranking-name {
        margin-left: 0.1rem;
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #666666;
      }
    }

    .ranking-right {
      display: flex;
      align-items: center;

      .right-num1 {
        font-family: PingFangSC-Semibold;
        font-weight: bold;
        font-size: 30px;
        color: #222222;
        letter-spacing: 0;
      }

      .right-num2 {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #222222;
        letter-spacing: 0;
      }
    }
  }

  .data-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 51px;

    .data-item {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .data-value {
        font-family: PingFangSC-Semibold;
        font-weight: bold;
        font-size: 42px;
        color: #3377FF;
        letter-spacing: 0;
      }

      .value-black {
        color: #222222;
      }

      .value-red {
        color: #F64E4F;
      }

      .data-name {
        font-family: PingFangSC-Regular;
        font-size: 24px;
        color: #666666;
        letter-spacing: 0;
        margin-top: 9px;
      }
      .data-name2 {
        @extend .data-name;
        margin-top: 4px;
      }
    }
  }
  .block-line-box{
    width:100%;
    height:1px;
    background: #EEEEEE;
    margin-top:0.3rem;
  }
  .tips-box{
    width:100%;
    height:0.72rem;
    display: flex;
    align-items: center;
    font-size: 0.24rem;
    color: #999999;
    .tips-icon{
      display: block;
      width:0.3rem;
      height:0.3rem;
      margin-right:0.06rem;
      margin-left:0.04rem;
    }
  }
}
