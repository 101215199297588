.snrc-image-picker-new {
  width: calc(100% - 60px);
  // background-color: red;
  margin-left: 30px;
  position: relative;
  overflow: visible;
  &-placeholder {
    position: absolute;
    color: #999999;
    top: 20px;
  }

  &-image-view {
    position: relative;
  }

  &-err {
    position: absolute;
    width: 100%;
    height: 100%;

    &-content {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .am-flexbox {
    overflow: visible
  }

  &-upload{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    z-index: 20;
    background: rgba(0, 0, 0, 0.4);
    &-content {
      display:flex;
      align-items: center;
      justify-content: center;
    }
    .am-activity-indicator-tip{
      color:#fff;
      opacity: 1;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;