.task-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  height: calc(100vh - 1.1rem);

  .list-view-container {
    height: 100%;
    flex: 1;

    .am-list-body {
      background: none;
    }

    .am-list-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .init-loading {
    background-color: rgba(242, 242, 242, .5);
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 33;

    img {
      width: .5rem;
    }

    p {
      color: #777;
      padding: .12rem 0;
    }

  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;