.shop-list-no-data {
  padding-top: 2rem;
  padding-bottom: 1.6rem;
  box-sizing: border-box;

  .no-data-pic {
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
    margin-bottom: 0.2rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .no-loading-pic {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    animation: loadingrotate 2.4s linear infinite;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .no-data-text {
    text-align: center;
    font-size: 0.3rem;
    line-height: 0.42rem;
    color: #222222;
  }

  .small-data-text {
    font-size: 0.24rem;
    color: #999999;
    margin-top: 0.1rem;
    text-align: center;
  }
}

@keyframes loadingrotate {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;