.src-new-switch{
  display: flex;
  height: 0.6rem;
  align-items: center;
  justify-content: center;
  padding:0.3rem 0;
  box-sizing: border-box;
  position: relative;
  .src-new-switch-title{
    width: 1.2rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    font-size: 0.26rem;
    color: #999999;
    background: #F0F0F0;
  }
  .src-new-switch-title:first-child{
    border-top-left-radius: 0.34rem;
    border-bottom-left-radius: 0.34rem;
  }
  .src-new-switch-title:last-child{
    border-top-right-radius: 0.34rem;
    border-bottom-right-radius: 0.34rem;
  }
}
.src-new-switch-suspension{
  position: absolute;
  left:0;
  top:0;
  width: 1.2rem;
  height: 0.6rem;
  line-height:0.6rem;
  text-align: center;
  display: block;
  font-size: 0.26rem;
  color: #FFFFFF;
  background: #3377FF;
  border-radius:0.34rem;
  font-size: 0.26rem;
  color: #FFFFFF;
  transition-duration: .3s;
  z-index:2;
}
// .snrc-switch {
//   @switch-redius: 27px;
//   @transform-duration: 0.3s;

//   width: 190px;
//   height: 58px;
//   background: #f0f0f0;
//   border-radius: @switch-redius;
//   position: relative;

//   &-title {
//     line-height: 58px;
//     text-align: center;
//     width: 50%;
//     display: inline-block;
//     padding-top:1px;
//     box-sizing: border-box;
//   }

//   &-suspension {
//     position: absolute;
//     background: #3377ff;
//     border-radius: @switch-redius;
//     left: 0;
//     top: 0;
//     bottom: 0;
//     width: 50%;
//     line-height: 58px;
//     text-align: center;
//     color: #ffff;
//     padding-top:1px;
//     box-sizing: border-box;
//   }

//   &-suspension-move {
//     transform: translateX(100%);
//     transition-duration: @transform-duration;
//   }

//   &-suspension-normal {
//     transform: translateX(0);
//     transition-duration: @transform-duration;
//   }
// }
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;