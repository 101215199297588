.index-store-location {
  width: 100%;
  height: 1rem;
  background-color: #ffffff;
  border-radius: 0.15rem;
  padding: 0 0.2rem 0 0.2rem;
  box-sizing: border-box;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nearest-fail {
    flex: 1;
    height: 1rem;
    display: flex;
    align-items: center;

    .nearest-fail-img {
      display: block;
      width: 0.4rem;
      height: 0.48rem;
      margin-right: 0.1rem;
    }

    .nearest-fail-text {
      height: 1rem;
      font-size: 0.28rem;
      color: #F64F4F;
      padding-top: 1px;
      line-height: 1rem;
      box-sizing: border-box;
    }

    .click-dialog-btn {
      width: 0.4rem;
      height: 0.4rem;
      background: url(../../../../../../assets/images/indexstorelocation-pic2@2x.png) center center no-repeat;
      background-size: 0.3rem 0.3rem;
      margin-left: 0.1rem;
    }
  }

  .nearest-succ-left {
    height: 100%;
    display: flex;
    width: 76%;
    padding-top: 0.55rem;
    align-items: flex-start;

    .nearest-tag-img {
      display: block;
      width: 0.4rem;
      height: 0.48rem;
      margin-right: 0.1rem;
    }

    .nearest-cont {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 0.5rem);

      .nearest-title {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0.05rem;

        .nearest-store-name {
          font-size: 0.28rem;
          color: #333333;
          max-width: 76%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: bold;
          margin-right: 0.1rem;
        }
      }

      .nearest-second-title {
        font-size: 0.2rem;
        display: flex;
        color: #999999;
        font-size: 0.2rem;

        span {
          color: #999999;
          font-size: 0.2rem;
        }

        .line-block {
          display: flex;
          padding: 0 0.11rem;
          margin-top: -0.03rem;
        }
      }


    }

  }

  .right-jump {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .right-jump-title {
      font-size: 0.24rem;
      color: #4a90e2;
    }

    .am-icon-xs {
      width: 0.36rem;
      height: 0.36rem;
      margin-top: 0.02rem;
    }

    .right-left-title {
      font-size: 0.24rem;
      color: #999999;
    }
  }

}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;