.table-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .table {
    border-collapse: collapse;
    &.table-fixed {
      table-layout: fixed;
    }
    width: 100%;
    th {
      padding: 0.1rem 0;
    }
    .table-cell {
      position: relative;
      display: inline-block;
    }
    .table-thead {
      .table-tr {
        background-color: #eaf1fc;
        position: relative;
        .table-th {
          box-sizing: border-box;
          height: 0.8rem;
          font-family: PingFangSC-Regular;
          font-size: 0.24rem;
          color: #666666;
          text-align: center;
          &.icon-padding {
            padding-right: 0.36rem;
          }
          .icon-sort {
            width: 0.28rem;
            height: 0.28rem;
            position: absolute;
            right: -0.06rem;
            top: 50%;
            transform: translate(100%, -50%);
            z-index: 20;
          }
          .th-subtitle {
            font-family: PingFangSC-Regular;
            font-size: 0.2rem;
            color: #999999;
            letter-spacing: 0;
            text-align: center;
          }
        }
      }
    }
    .table-tbody {
      .table-tr {
        .table-td {
          box-sizing: border-box;
          height: 1rem;
          font-family: PingFangSC-Regular;
          font-size: 0.26rem;
          color: #333333;
          text-align: center;
          border-bottom: 1px solid #e0e0e0;
          .group-wrapper-container {
            display: inline-block;
            overflow: hidden;
            .group-wrapper {
              &:first-child {
                margin-top: 0.12rem;
              }
              overflow: hidden;
              margin-bottom: 0.15rem;
              position: relative;
              &:not(:first-child)::before {
                content: '';
                display: block;
                position: absolute;
                background-color: #f7f7f7;
                margin: 0 0.28rem;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
              }
              &:first-child {
                .group-name::before {
                  background-color: #f3f7fc; // #F3F7FC
                }
              }
              &:not(:first-child) {
                .group-name::before {
                  border-bottom: 1px solid #e6e6e6;
                }
              }
              .group-name {
                will-change: transform;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 0.32rem;
                box-sizing: border-box;
                > span {
                  position: relative;
                  z-index: 10;
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin: 0 0.2rem;
                }
                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  margin: 0 0.28rem;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                }
                font-family: PingFangSC-Medium;
                font-size: 0.24rem;
                color: #333333;
                text-align: center;
                line-height: 0.52rem;
                font-weight: bold;
              }
              .group-content {
                position: relative;
                z-index: 10;
                display: flex;
                .group-item {
                  &.icon-padding {
                    padding-right: 0.36rem;
                  }
                  flex: 1 0 auto;
                  .group-item-value {
                    padding-top: 0.1rem;
                    line-height: 0.38rem;
                    font-family: PingFangSC-Regular;
                    font-size: 0.26rem;
                    color: #333333;
                    text-align: center;
                  }
                  .group-item-subtitle {
                    font-family: PingFangSC-Regular;
                    font-size: 0.22rem;
                    color: #999999;
                    text-align: center;
                    line-height: 0.3rem;
                    padding: 0.2rem 0 0.17rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.btn-wrapper {
  background-color: #fff;
  padding: 0.14rem 0 0.22rem;
  will-change: transform;
  .open-close-btn {
    text-align: center;
    width: 2rem;
    color: #666666;
    line-height: 0.52rem;
    font-size: 0.24rem;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 0.04rem;
    span,
    .btn-icon {
      vertical-align: middle;
    }
    .btn-icon {
      color: #999999;
      margin-left: 0.1rem;
      line-height: 0.52rem;
      width: 0.32rem;
      height: 0.32rem;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;