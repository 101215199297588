.header {
  padding-bottom: 0.6rem;

  &-more {
    height: 100px;
    background-color: #3766f4;
    position: relative;

    &-midline {
      position: absolute;
      left: 50%;
      width: 1px;
      opacity: 0.3;
      background: #ffffff;
      top: 30px;
      bottom: 30px;
    }

    &-item {
      width: 50%;

      &-title {
        font-size: 22px;
        color: #ffffff;
      }

      &-content {
        font-size: 42px;
        color: #ffffff;
      }
    }
  }
}

.top {
  position: relative;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .top-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-left: 0.3rem;

    .left-img {
      width: 90px;
      height: 90px;
      line-height: 90px;
      border-radius: 45px;
    }

    .biaogan {
      position: absolute;
      width: 1.24rem;
      height: 1.24rem;
      margin-left: -0.18rem;
      margin-top: -0.2rem;
    }

    .qianli {
      position: absolute;
      width: 1.24rem;
      height: 1.24rem;
      margin-left: -0.18rem;
      margin-top: -0.2rem;
    }

    .left-user {
      flex: 1;
      margin-left: 0.2rem;
      margin-right: 0.2rem;

      .user-name {
        font-size: 0.22rem;
        font-weight: 500;
        color: #ffffff;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .user-role {
        display: flex;
        text-align: center;
        align-items: center;
        margin-top: 1px;
        padding: 0.04rem 0.12rem 0.04rem 0.12rem;
        background-color: #ffffff;
        border: 1px solid #ff7b2b;
        border-radius: 16px;
        font-size: 0.2rem;
        color: #ff7b2b;
        box-sizing: border-box;

        .role-more {
          margin-left: 5px;
          width: 14px;
          height: 8.6px;
        }
      }

      .user-role-store {
        width: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.26rem;
        color: white;
        box-sizing: border-box;
        margin-bottom: 0.08rem;

        .role-store-more {
          margin-left: 5px;
          width: 22px;
          height: 11px;
        }
      }
    }
  }

  .top-right {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 0.5rem;
    color: #ffffff;

    .right-area {
      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
      height: 40px;
      line-height: 40px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      &-more {
        margin-left: 10px;
        width: 14px;
        height: 8.6px;
      }
    }

    .right-date {
      display: inline-block;
      font-size: 22px;
      height: 30px;
      line-height: 30px;
      margin-top: 0.1rem;
    }

    .user_honour {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 0.24rem;
      background: #78ABFE;
      display: inline-block;
      padding: 0.02rem 0.15rem;
      padding-top: 0.04rem;
      display: flex;
      margin-left: 0.05rem;
      min-width: 1.6rem;
      height: 0.45rem;

      .icon {
        width: 0.27rem;
        height: 0.35rem;
        margin-top: 2px;
        margin-left: 4px;
        margin-right: 0.06rem;
      }

      .text {
        font-size: 0.2rem;
        color: #FFFFFF;
        margin-right: 0.06rem;
        white-space: nowrap;
      }

      .right-triangle {
        font-size: 0;
        line-height: 0;
        border-width: 0.08rem;
        border-color: #FFFFFF;
        border-bottom-width: 0;
        border-style: dashed;
        border-top-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
        transform: rotate(-90deg);
      }
    }
  }

  .header-line {
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 40px;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
  }
}

.role-name-txt {
  font-size: 20px;
  color: #FF6600;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;