/*
 * @Author: 柯庆荣19040892
 * @Email: 19040892@cnsuning.com
 * @Date: 2019-10-28 14:28:28
 * @Last Modified by: 柯庆荣19040892
 * @Last Modified time: 2019-11-15 14:30:03
 * @Description: Description
 */
.evaluation-pie {
  position: relative;

  &-small {
    width: 110px;
    height: 110px;

    .evaluation-pie-canvas,
    .evaluation-pie-text {
      width: 110px;
      height: 110px;
    }

    .evaluation-pie-title {
      font-size: 26px;
      line-height: 30px;
    }

    .evaluation-pie-subtitle {
      font-size: 13px;
      line-height: 18px;
    }
  }

  &-large {
    width: 220px;
    height: 220px;

    .evaluation-pie-canvas,
    .evaluation-pie-text {
      width: 220px;
      height: 220px;
    }

    .evaluation-pie-title {
      font-size: 52px;
      line-height: 60px;
    }

    .evaluation-pie-subtitle {
      font-size: 26px;
      line-height: 36px;
    }
  }
}

.evaluation-pie-text {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.evaluation-pie-title {
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0;
  text-align: center;
  line-height: 30px;
}

.evaluation-pie-subtitle {
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0;
  text-align: center;
  line-height: 18px;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;