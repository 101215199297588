.message-tab-view {
  .global-no-data-wrap {
    padding-top: 2rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0;
    z-index: 14;

    .no-data-text {
      color: #fff;
    }
  }

  .tool {
    background-color: white;
    display: flex;
    width: 100%;
    padding: 0 0.26rem 0 0.36rem;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    box-sizing: border-box;
    position: relative;

    .tool-dot {
      position: absolute;
      left: 0.12rem;
      top: 0.37rem;
      width: 0.12rem;
      height: 0.12rem;
      display: block;
      background-color: transparent;
      border-radius: 0.18rem;
    }

    .tool-dotActive {
      position: absolute;
      left: 0.12rem;
      top: 0.37rem;
      width: 0.12rem;
      height: 0.12rem;
      display: block;
      background-color: #ff3300;
      border-radius: 0.18rem;
    }

    .essay {
      width: 100%;
      height: 123px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .text {
        width: 100%;
        margin-top: 25px;
        font-size: 28px;
        font-weight: bold;
        color: black;
        padding-bottom: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .stext {
        font-size: 24px;
        color: #999999;
      }
    }
  }

  .tool:last-child {
    .essay {
      border-bottom: 0px;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;