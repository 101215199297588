@import '../varible.less';

.tab-bar-sel {
	// height: 0.68rem;
	background: #fff;
	width: 100%;

	::-webkit-scrollbar {
		/*隐藏滚轮*/
		display: none;
	}

	ul {
		margin: 0;
		overflow-x: auto;
		white-space: nowrap;
		padding-inline-start: 0.2rem;
		height: 0.5rem;

		li {
			display: inline-block;
			text-align: center;
			list-style: none;
			margin-right: 0.15rem;
			padding: 0 0.2rem;
			font-size: 0.28rem;
			color: #666666;

			.cell-radio {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.text {
					font-size: 0.28rem;
				}
				.tab {
					background: @primary-color;
					color: #666666;
					height: 0.04rem;
					margin-top: 0.05rem;
					width: 0.5rem;
					margin-top: 0.1rem;
				}
				.tab-none {
					background: @primary-color;
					color: #666666;
					visibility: hidden;
					height: 0.02rem;
					margin-top: 0.05rem;
					margin-top: 0.1rem;
				}
			}
		}

		.active {
			color: @primary-color;
		}
	}
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;