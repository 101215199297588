.achievement-canvas {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: -0.5rem;

  .canvas-inner {
    position: relative;

    .rating {
      left: 0;
      right: 0;
      height: 95px;
      background: transparent;
      position: absolute;
      bottom: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      .stars {
        display: flex;
        align-items: center;
        justify-content: center;

        .star-big {
          width: 30px;
          height: 30px;
          margin-left: 20px;
          margin-right: 20px;
        }

        .star-little {
          width: 20px;
          height: 20px;
        }
      }

      .des-bg {
        line-height: 40px;
        border-radius: 0.5rem;
        font-size: 28px;
        padding: 0.05rem 0.2rem;
        color: white;
      }
    }

    .modify-tuozhan-rating {
      .des-bg {
        font-size: 26px !important;
        padding: 0.04rem 0.18rem !important;
      }
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;