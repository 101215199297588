.custorm-tab {
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 26px;
  color: #222222;
  letter-spacing: 0;
  text-align: justify;
  // line-height: 24px;
  background: #FFFFFF;
}

.custorm-tab-item {
  display: flex;
  flex-direction: column;
  width: 160px;
  color: #333333;
  align-items: center;
}

.custorm-tab-item-active {
  display: flex;
  flex-direction: column;
  width: 160px;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 12px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  font-weight: bold;
  // line-height: 30px;
}

.custorm-tab-item:last-child {
  padding-right: 0px;
}


.custorm-tab-bar {
  width: 30px;
  height: 8px;
  border-radius: 12px;
  background: #3377FF;
  margin-top: 6px;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;