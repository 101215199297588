.message-dialog-container {
  .guide-dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 1010;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .guide-dialog-wrap {
    position: fixed;
    overflow: auto;
    padding-top: 1px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: 1011;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    box-sizing: border-box;

    .guide-page {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }

    .img {
      display: block;
      width: 4.19rem;
      height: 3.67rem;
      margin: 0 auto;
    }

    .btn-sure {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 4.47rem;
      width: 1.96rem;
      height: 0.68rem;
      line-height: 0.68rem;
      text-align: center;
      background: #3377FF;
      border-radius: 8px;
      color: #fff;
      font-size: 0.3rem;
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;