.snrc-switch {
  @switch-redius: 27px;
  @transform-duration: 0.3s;

  width: 190px;
  height: 58px;
  background: #f0f0f0;
  border-radius: @switch-redius;
  position: relative;

  &-title {
    line-height: 58px;
    text-align: center;
    width: 50%;
    display: inline-block;
    padding-top:1px;
    box-sizing: border-box;
  }

  &-suspension {
    position: absolute;
    background: #3377ff;
    border-radius: @switch-redius;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    line-height: 58px;
    text-align: center;
    color: #ffff;
    padding-top:1px;
    box-sizing: border-box;
  }

  &-suspension-move {
    transform: translateX(100%);
    transition-duration: @transform-duration;
  }

  &-suspension-normal {
    transform: translateX(0);
    transition-duration: @transform-duration;
  }
}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;