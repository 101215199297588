.palceholder-view {
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-image {
    width: 300px;
    height: 300px;
    margin-top: -300px;
    // background-color: #eeeeee
  }
  &-title {
    margin: 20px 0 10px 0;
  }
  &-subtitle {
    color: #666666;
  }


  .placeholder-view-tips {
    width: 80%;
    margin:10px auto;
    text-align: center;
    color: #666666;
    font-size: 26px;
    line-height: 38px;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;