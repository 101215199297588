.title {
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.35rem 0 0.35rem;
  background: white;

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0.1rem;

    .title-name {
      font-size: 0.28rem;
      color: #333333;
    }

    .sub-title-name {
      font-size: 0.24rem;
      color: #333333;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .right-name {
      font-size: 0.25rem;
      color: #666666;
    }
  }

  .mgleft {
    margin-left: 0.15rem;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;