.achievement-score {
  width: 100%;
  padding: 0 0.24rem 0 0.24rem;
  box-sizing: border-box;

  .yyyj-wrap {
    width: 100%;
    height: 0.9rem;
    padding: 0 0.2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-box {
      display: flex;
      align-items: center;

      .yyyj-icon {
        display: block;
        width: 0.42rem;
        height: 0.42rem;
        margin-right: 0.1rem;
      }

      .left-text-box {
        display: flex;
        align-items: center;
        margin-right: 0.2rem;

        .name {
          font-size: 0.24rem;
          color: #FFFFFF;
          white-space: nowrap;
        }

        .num {
          font-size: 0.36rem;
          color: #FFFFFF;
          margin-top: -0.06rem;
        }
      }

      .left-text-box2 {
        margin-right: 0;
        display: flex;
        align-items: center;

        .name {
          font-size: 0.24rem;
          color: #FFFFFF;
          white-space: nowrap;
        }

        .num {
          font-size: 0.36rem;
          color: #FFFFFF;
          margin-top: -0.06rem;
        }
      }
    }

    .right-box {
      width: 120px;
      height: 40px;
      line-height: 40px;
      padding: 0.02rem 0.1rem;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: content-box;

      .right-text {
        margin-right: 0.04rem;
        font-size: 20px;
        white-space: nowrap;
        color: #FFFFFF;
      }

      .right-triangle {
        font-size: 0;
        line-height: 0;
        border-width: 0.08rem;
        border-color: #FFFFFF;
        border-bottom-width: 0;
        border-style: dashed;
        border-top-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
        transform: rotate(-90deg);
      }
    }
  }
}
@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;