@import '../varible.less';

.snrc-time-picker-bar {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .selected-time {
    display: flex;
    position: relative;
    width: 100%;
    height: 0.88rem;
    line-height: 0.32rem;
    align-items: center;
    z-index: 399;
    background-color: #fff;

    .select-name {
      font-size: 0.24rem;
      color: #333333;
      padding-left: 0.28rem;
      padding-right: 0.28rem;
    }

    .shops-name {
      font-size: 0.28rem;
      color: #333333;
    }

    .triangle {
      flex: 0 0 auto;
      width: 0.2rem;
      height: 0.12rem;
      margin: 0 0.28rem;
      background: url('../../assets/images/Triangle.png') no-repeat center;
      background-size: cover;
    }
  }

  .select-condition {
    position: absolute;
    left: 0;
    //top: 0.88rem;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    max-height: 7rem;
    overflow-y: auto;

    .time-duration {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-left: 0.2rem;
      height: 0.8rem;

      .condition-tag {
        font-size: 0.28rem;
        color: #333;
      }

      .conditon-time {
        // font-size: 0.28rem;// color: #333;
        border-radius: 5px;
        border: 0.02rem solid #f0f1f4;
        // padding: 0.1rem 0.4rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        height: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .buttons {
      display: flex;

      .cancel {
        width: 50%;
        height: 1rem;
        border-right: 1px solid #f0f1f4;
        text-align: center;
        line-height: 1rem;
      }

      .confirm {
        width: 50%;
        height: 1rem;
        text-align: center;
        line-height: 1rem;
        color: @primary-color;
      }
    }
  }

  .view-mask {
    position: fixed;
    z-index: 900;
    left: 0;
    top: 0.88rem;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .hide {
    display: none !important;
  }

  .am-list-item {
    width: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    min-height: 0;

    .am-list-line {
      padding-right: 0;

      .am-list-extra {
        display: none;
      }

      .am-list-content {
        flex: none;
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        font-size: 0.24rem;
      }

      .am-list-arrow {
        display: none;
      }
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;