.table-wrapper {
  width: 100%;
  .table {
    border-collapse: collapse;
    th {
      padding: 0.1rem 0;
    }
    td {
      padding: 0.15rem 0;
    }
    .table-cell {
      position: relative;
      display: inline-block;
    }
    .table-thead {
      .table-tr {
        &.gray {
          background-color: #eeeeee;
        }
        background-color: #eaf1fc;
        position: relative;
        .table-th {
          box-sizing: border-box;
          height: 0.8rem;
          font-family: PingFangSC-Regular;
          font-size: 0.24rem;
          color: #666666;
          text-align: center;
          &.icon-padding {
            padding-right: 0.36rem;
          }
          .table-cell {
            white-space: nowrap;
          }
          .icon-sort {
            width: 0.28rem;
            height: 0.28rem;
            position: absolute;
            right: -0.04rem;
            top: 50%;
            transform: translate(100%, -50%);
          }
          .th-subtitle {
            font-family: PingFangSC-Regular;
            font-size: 0.2rem;
            color: #999999;
            letter-spacing: 0;
            text-align: center;
          }
        }
      }
    }
    .table-tbody {
      .table-tr {
        &:nth-child(2n) {
          &.gray {
            background-color: #f7f7f7;
          }
          background-color: #f3f7fc;
        }
        .table-td {
          box-sizing: border-box;
          height: 1rem;
          font-family: PingFangSC-Regular;
          font-size: 0.26rem;
          color: #333333;
          text-align: center;
          &:first-child {
            position: relative;
          }
          .td-subtitle {
            font-family: PingFangSC-Regular;
            font-size: 0.22rem;
            color: #999999;
            text-align: center;
          }
        }
      }
    }
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;