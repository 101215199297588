.sample-machine-data {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 0.2rem;
  border-radius: .15rem;

  .home-area-purchase-sub-item {
    width: 100%;

    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-top: -0.04rem;
      margin-right: 0.14rem;
    }
  }

  .home-area-purchase-sub-item-title {
    margin-left: 0.1rem;
    font-size: 0.36rem;
    color: #333333;
    font-weight: bold;
  }

  .home-area-purchase-sub-item-detail {
    font-size: 0.24rem;
    color: #4a90e2;
  }

  &-sub-item {
    width: 100%;

    &-title {
      margin-left: 10px;
      font-size: 30px;
      color: #333333;
      font-weight: bold;
    }

    &-detail {
      font-size: 24px;
      color: #4a90e2;
    }

    .detail-content {
      height: 50px;
      width: 200px;
    }


    &-image {
      width: 32px;
      height: 32px;
    }
  }

  .score-list {
    width: 100%;

    .score-item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1.1rem;
      width: 25%;

      .score {
        font-size: 0.4rem;
        color: #222222;
        font-weight: bold;
      }

      .score-font {
        font-size: 0.46rem;
      }

      .border-right {
        border-right: 1px solid #666666;
        font-size: 0.46rem;
      }

      .score-name {
        font-size: 0.24rem;
        color: #666666;
        margin-top: 0.1rem;
      }
    }
  }

}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;