@import '../varible.less';
.navbar-container {
  width: 100%;
  padding-top: 0.88rem;
  box-sizing: border-box;
}
.navbar-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  height: 0.88rem;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  line-height: 0.88rem;
  z-index: 499;
  &.navbar-border {
    border-bottom: 1px solid #ddd;
  }
  .left-wrapper {
    padding-left: 0.3rem;
    line-height: inherit;
    .back-icon {
      font-size: 0.32rem;
    }
  }
  .title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: inherit;
    font-family: PingFangSC-Medium;
    font-size: 0.34rem;
    color: #333333;
    font-weight: bold;
    white-space: nowrap;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
  .tab-wrapper {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    .tabItem {
      font-family: PingFangSC-Medium;
      font-size: 0.28rem;
      color: #333333;
      position: relative;
      padding: 0 0.06rem;
      white-space: nowrap;
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0.06rem;
        border-radius: 0.06rem;
      }
      &.active {
        color: @primary-color;
        &::after {
          background-color: @primary-color;
        }
      }
      &:not(:first-child) {
        margin-left: 0.64rem;
      }
    }
  }
  .right-wrapper {
  }
}

.content-wrapper {
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;