@import './page.less';

body,
html,
#root {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Source Han Sans SC', 'Mi Lan Pro VF', 'Mi Lanting', 'Noto Sans CJK SC', 'Microsoft YaHei', 'Heiti SC', 'DengXian', sans-serif;
  background: #f2f2f2;
  min-width: 100%;
}

/**
 * 默认禁用长按图片提示保存
 */
img {
  -webkit-touch-callout: none;
}

.am-search-input .am-search-clear {
  padding: 0.13rem !important;
}

.motion-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
}

.layout-container {
  position: relative;
  height: 100vh;
  overflow-y: auto;

  &.with-tabs {
    height: calc(100vh - 1rem);
  }

  &.with-cache {
    overflow-x: hidden;
    overflow-y: hidden;

    .ka-wrapper,
    .ka-content {
      height: 100%;
      overflow-y: auto;

      & > div {
        position: relative;
      }
    }
  }
}

.pswp__icn {
  width: .52rem !important;
  height: .52rem !important;
}

.pswp__button {
  width: 0.7rem !important;
  height: 0.8rem !important;
}

.pswp__counter {
  font-size: .26rem !important;
}

.am-modal-button {
  &::before {
    display: none;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;