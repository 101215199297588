@import '../varible.less';
.shops-bar {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .shops-now {
    display: flex;
    position: relative;
    width: 100%;
    height: 0.88rem;
    line-height: 0.32rem;
    align-items: center;
    z-index: 498;
    background-color: #fff;

    .shops-icon {
      flex: 0 0 auto;
      width: 0.52rem;
      height: 0.52rem;
      margin-right: 0.12rem;
      margin-left: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 80% !important;
        height: 80% !important;
      }
    }

    .shops-name {
      flex: 0 1 auto;
      font-size: 0.32rem;
      color: #333;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      line-height: 0.88rem;
    }

    .triangle {
      flex: 0 0 auto;
      width: 0.2rem;
      height: 0.12rem;
      margin: 0 0.2rem;
      background: url('../../assets/images/Triangle.png') no-repeat center;
      background-size: cover;
    }
  }

  .shops-list {
    position: absolute;
    left: 0;
    top: 0.9rem;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    max-height: 7rem;
    overflow-y: auto;

    .shop-one {
      display: flex;
      align-items: center;
      height: 0.88rem;
      border-bottom: 0.02rem solid #ddd;

      .shops-name {
        flex: 1;
        margin-left: 0.28rem;
        font-size: 0.28rem;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 100%;
        line-height: 0.88rem;
      }

      .shops-name-check {
        color: @primary-color !important;
      }

      .shops-check {
        flex: 0 0 auto;
        display: block;
        width: 0.38rem;
        height: 0.25rem;
        margin-right: 0.28rem;
        background: url('../../assets/images/store-check.png') no-repeat center;
        background-size: cover;
      }
    }
  }

  .shops-mask {
    position: fixed;
    z-index: 450;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .hide {
    display: none !important;
  }

  .bor-b {
    border-bottom: 0.02rem solid #ddd;
  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;