.snrc-search-bar {

  .snrc-search-bar-content {
    .am-search-input {
      background-color: #eeeeee;
      border-radius: 28px;
      .am-search-synthetic-ph {
        text-align: left;
        padding-left: 30px;
      }
    }

    .am-search-cancel {
      color: #333333;
      font-size: 28px;
    }

  }
}


.android-snrc-search-bar {
  .snrc-search-bar-content {
    .am-search-input {
      background-color: #eeeeee;
      border-radius: 28px;
      .am-search-synthetic-ph {
        text-align: left;
        padding-left: 30px;
        top: 6px !important;
      }
    }

    .am-search-cancel {
      color: #333333;
      font-size: 28px;
    }

    .am-search-value{
      top: 1px !important;
    }

  }
}

@hd: 2px;@brand-primary: #3377FF;@brand-primary-tap: #ee8a00;@brand-success: #6abf47;@brand-warning: #ffc600;@brand-error: #f4333c;@brand-important: #ff5b05;@brand-wait: #108ee9;@color-text-base: #333;@search-bar-fill: #fff;@search-bar-font-size: 28px;